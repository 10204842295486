
import Vue from 'vue'

export default Vue.extend<Data, Computed, Methods, Props>({
  props: {
    item: {
      type: Object,
      default: () => ({
        shopName: '',
        subscribeCount: 0,
        icon: '',
      }),
    },
  },
  methods: {
    handleActive() {
      this.$emit('handleActive', this.item)
    },
  },
})

interface Data {}
interface Computed {}
interface Methods {}
interface Props {
  item: any
}
