
import Vue from 'vue'

export default Vue.extend<Data, Computed, Methods, Props>({
  props: {
    item: {
      type: Object,
      default: () => ({ categoryName: '' }),
    },
    firstItem: {
      type: Boolean,
      default: false,
    },
    lastItem: {
      type: Boolean,
      default: false,
    },
  },
})

interface Data {}
interface Computed {}
interface Methods {}
interface Props {
  item: any
  lastItem: boolean
  firstItem: boolean
}
