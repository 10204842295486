import { render, staticRenderFns } from "./LiveCount.vue?vue&type=template&id=3a01d358&scoped=true&"
import script from "./LiveCount.vue?vue&type=script&setup=true&lang=ts&"
export * from "./LiveCount.vue?vue&type=script&setup=true&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a01d358",
  null
  
)

export default component.exports