
import Vue from 'vue'
export default Vue.extend<Data, Computed, Methods, Props>({
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    itemKey: {
      type: String,
      default: 'title',
    },
  },
})

interface Data {}
interface Computed {}
interface Methods {}
interface Props {
  item: any
  itemKey: string
}
