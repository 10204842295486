import { render, staticRenderFns } from "./ShoppingLiveCardY.vue?vue&type=template&id=62838f7c&scoped=true&"
import script from "./ShoppingLiveCardY.vue?vue&type=script&setup=true&lang=ts&"
export * from "./ShoppingLiveCardY.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./ShoppingLiveCardY.vue?vue&type=style&index=0&id=62838f7c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62838f7c",
  null
  
)

export default component.exports