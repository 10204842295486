
import Vue, { PropType } from 'vue'
import { mapActions, mapState } from 'vuex'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import Chip from '@/components/common/slider/component/Chip.vue'
import Mall from '@/components/common/cards/Mall.vue'
import ProductCardY from '@/components/common/widgets/ProductCardY.vue'
import ShoppingLiveCardY from '@/components/common/live/ShoppingLiveCardY.vue'
import DibsTabItem from '@/components/common/slider/component/DibsTabItem.vue'
import CustomChip from '@/components/common/slider/component/CustomChip.vue'
import SearchMallCard from '@/components/common/slider/component/SearchMallCard.vue'

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    Swiper,
    SwiperSlide,
    Chip,
    Mall,
    ProductCardY,
    DibsTabItem,
    CustomChip,
    SearchMallCard,
    ShoppingLiveCardY,
  },
  filters: {
    sliderContainer(item: boolean) {
      if (item) return 'div'
      return 'swiper'
    },
    sliderItem(item: boolean) {
      if (item) return 'div'
      return 'swiper-slide'
    },
    sliderItemComponent(item: SliderInComponentName) {
      switch (item) {
        case 'chip':
          return 'Chip'
        case 'mall':
          return 'Mall'
        case 'productCardY':
          return 'ProductCardY'
        case 'dibsTabItem':
          return 'DibsTabItem'
        case 'customChip':
          return 'CustomChip'
        case 'searchMallCard':
          return 'SearchMallCard'
        case 'shoppingLiveCardY':
          return 'ShoppingLiveCardY'
      }
    },
  },
  props: {
    active: {
      type: Object,
      default: () => ({ yn: false, item: {} }),
    },
    itemKey: {
      type: String,
      default: '',
    },
    touchless: {
      type: String,
      default: '',
    },
    list: {
      type: Array,
      default: () => [],
    },
    // 구독몰에선 다른 정보의 리스트 두개를 병합하여 사용
    list2: {
      type: Array,
      default: () => [],
    },
    sliderInName: {
      type: String as PropType<SliderInComponentName>,
      default: 'productCardY',
    },

    itemClass: {
      type: String,
      default: '',
    },
    itemActiveClass: {
      type: Array,
      default: () => ['', ''],
    },
    activeKey: {
      type: String,
      default: 'id',
    },
    firstSlot: {
      type: Boolean,
      default: false,
    },
    lastSlot: {
      type: Boolean,
      default: false,
    },
    swipeClass: {
      type: String,
      default: '',
    },
    swipeSliderClass: {
      type: String,
      default: '',
    },
    useNotfirst: {
      type: Boolean,
      default: false,
    },
    differentItemClass: {
      type: Array,
      default: () => ['', '', ''],
    },
    mallProps: {
      type: Object,
      default: () => ({}),
    },
    newMallProps: {
      type: Object,
      default: () => ({}),
    },
    showDivider: {
      type: Boolean,
      default: false,
    },
    imageKey: {
      type: String,
      default: 'icon',
    },
    badgeToggleKey: {
      type: String,
      default: '',
    },
    badgeIcons: {
      type: Array,
      default: () => [],
    },
    indexKey: {
      type: String,
      default: '',
    },
    onVertical: {
      type: Boolean,
      default: true,
    },
    centerActive: {
      type: Boolean,
      default: false,
    },
    gtagName: {
      type: String,
      default: '',
    },
    aspectRatio: {
      type: Number,
      default: 1 / 1,
    },
  },
  data() {
    return {
      activeItem: { id: '', shopId: '' },
      activeIndex: 0,
      swiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 0, // swiper-slide 사이의 간격 지정
        slidesOffsetBefore: 0, // slidesOffsetBefore는 첫번째 슬라이드의 시작점에 대한 변경할 때 사용
        slidesOffsetAfter: 0, // slidesOffsetAfter는 마지막 슬라이드 시작점 + 마지막 슬라이드 너비에 해당하는 위치의 변경이 필요할 때 사용
        freeMode: true, // freeMode를 사용시 스크롤하는 느낌으로 구현 가능
        speed: 500,
        centeredSlides: this.centerActive,
        slideToClickedSlide: this.centerActive,
        centeredSlidesBounds: this.centerActive,
      },
      count: 0,
      touchY: 0,
    }
  },
  watch: {
    list() {
      this.count += 1
      const { yn, item } = this.active

      if (yn) this.activeItem = item
      else this.activeItem = this.list[0]

      if (item.categoryName === '전체' || item.title === '전체') {
        const sliderEl: any = this.$refs.sliderContainer
        sliderEl.scrollLeft = 0
      }
      // list 변경 시, 슬라이드 위치 초기화
    },
    refreshNoti() {
      const sliderEl: any = this.$refs.sliderContainer
      sliderEl.scrollLeft = 0
    },
    //active.item data reactivity
    active() {
      if (this.active.yn) {
        this.activeItem = this.active.item
      }
    },
  },
  created() {
    if (this.active.yn) {
      const idx = this.list.findIndex((item) => item.id === this.active.item.id)
      this.activeItem = this.active.item
      this.$nextTick(() => {
        this.handleActive(this.activeItem, idx, true)
      })
    } else {
      this.activeItem =
        this.list.length > 0 ? this.list[0] : { id: '', shopId: '' }
    }
  },
  computed: {
    ...mapState('PullToRefreshStore', ['refreshNoti']),
  },
  mounted() {
    // Pull To Refresh 와 touch handlering을 위한 touch events
    const sliderContainer = this.$refs.sliderContainer as HTMLElement
    this.$nextTick(() => {
      sliderContainer.addEventListener('touchmove', this.handleTouchmove)
      sliderContainer.addEventListener('touchend', this.handleTouchend)
    })
  },
  beforeDestroy() {
    // Pull To Refresh 와 touch handlering을 위한 touch events
    const sliderContainer = this.$refs.sliderContainer as HTMLElement
    sliderContainer.removeEventListener('touchmove', this.handleTouchmove)
    sliderContainer.removeEventListener('touchend', this.handleTouchend)
  },
  methods: {
    ...mapActions('PullToRefreshStore', ['fetchTouchless']),
    swipeHandler(data: string) {
      if (!this.touchless) return
      if (data !== 'off') {
        this.$store.dispatch(`${this.touchless}/fetchSwipe`, { yn: true })
      } else {
        setTimeout(() => {
          this.$store.dispatch(`${this.touchless}/fetchSwipe`, { yn: false })
        })
      }
    },
    handleActive(item: any, index: number, init: boolean) {
      // Mobile 일 떄
      // PC 경우 awesome swiper option setting
      if (this.$isMobile() && (this.centerActive || init)) {
        if (this.$refs.sliderContainer) {
          const sliderEl: any = this.$refs.sliderContainer

          let moveValue = 0
          try {
            if (index <= 2) {
              moveValue = 0
            }
            if (index >= sliderEl.children.length - 3) {
              moveValue = sliderEl.scrollWidth
            }
            if (index > 2 && index < sliderEl.children.length - 3) {
              for (let i = 0; i < index; i++) {
                moveValue += sliderEl.children[i].clientWidth
              }
              moveValue -=
                sliderEl.clientWidth / 2 -
                sliderEl.children[index].clientWidth / 2 -
                8
            }
          } catch (e) {
            console.log('e', e)
          } finally {
            sliderEl.scrollLeft = moveValue
          }
        }
      }
      this.activeIndex = index
      this.activeItem = item
      this.$emit('handleActive', item, index)
    },
    // Pull To Refresh 와 touch handlering을 위한 touch move
    handleTouchmove(e: TouchEvent) {
      if (this.touchY === 0) {
        this.fetchTouchless(true)
        this.touchY = e.changedTouches[0].clientY
        return
      }
      const touchEvent = e.changedTouches[0]
      const screenY = touchEvent.clientY
      const MIN_PULL_HEIGHT = 150
      // MIN_PULL_HEIGHT -> 땡겼다는 걸 알 수 있는 최소 높이
      if (screenY > this.touchY + MIN_PULL_HEIGHT) {
        this.fetchTouchless(false)
      } else {
        this.fetchTouchless(true)
      }
    },
    // Pull To Refresh 와 touch handlering을 위한 touch end
    handleTouchend(e: TouchEvent) {
      this.fetchTouchless(false)
    },
  },
})

type SliderInComponentName =
  | 'chip'
  | 'mall'
  | 'productCardY'
  | 'dibsTabItem'
  | 'customChip'
  | 'searchMallCard'
  | 'shoppingLiveCardY'

interface Data {
  swiperOption: SwiperOption
  activeItem: any
  count: number
  activeIndex: number
  touchY: number
}
interface Methods {
  swipeHandler(data: string): void
  handleActive(item: any, index: number, init?: boolean): void
  handleTouchmove(e: TouchEvent): void
  handleTouchend(e: TouchEvent): void
  fetchTouchless(yn: boolean): Promise<void>
}
interface Computed {
  refreshNoti: boolean
}
interface Props {
  itemKey: string
  // 해당 slider 상위에 swipe 이벤트가 존재할 시, 해당 swipe 이벤트 동작을 막기 위해 스토어를 활용
  // fetchSwipe 함수로 통일이 되게 사용하며, 해당 스토어명을 내려주면 됨
  touchless: string
  // 나열할 아이템
  list: Array<any>
  //swipe class - 1순위
  swipeClass: string
  //swipe slider class - 2순위
  swipeSliderClass: string
  // item component에 들어갈 class - 3순위
  itemClass: string
  // sliderItem 내부에 놓여질 태그
  sliderInName: SliderInComponentName
  // item component에 들어갈 active check key
  // 리스트 0번째가 아닌 지정 active
  active: { yn: boolean; item: any }
  // default : id
  activeKey: string
  // item component에 들어갈 active class array  [true, false]
  itemActiveClass: string[]
  // v-for 규칙성에서 제외된 첫 번째 slot 사용 여부
  firstSlot: boolean
  // v-for 규칙성에서 제외된 마지막 slot 사용 여부
  lastSlot: boolean
  // 첫 번째 리스트 아이템을 쓰지 않는 경우가 있음 -- 후에 공통있게 수정
  useNotfirst: boolean
  // [(1),(2),(3)] 1: 첫번째 아이템 / 2 : (1),(3) 제외한 모든 아이템 / 3 : 마지막 아이템 클래스
  differentItemClass: string[]
  // list item 기반이 아닌 mall Props
  mallProps: any
  newMallProps: any
  // 구분자 ( 찜 구독몰에서만 사용 )
  showDivider: boolean
  list2: any
  //  사용할 이미지 데이터 key
  imageKey: string
  // 뱃지 아이콘 두개 사용하는 곳이 있음 체크할 키값
  badgeToggleKey: string
  // 토클게 사용할 badge string []
  badgeIcons: string[]
  indexKey: string
  onVertical: boolean
  // 아이템 선택 시, 중앙 정렬 사용 여부
  centerActive: boolean
  gtagName: string
  aspectRatio: number
}
