
import Vue from 'vue'
export default Vue.extend<Data, Computed, Methods, Props>({
  props: {
    item: {
      type: Object,
      default: () => ({
        categoryCode: 0,
        categoryName: '',
        productCount: 0,
      }),
    },
  },
})

interface Data {}
interface Computed {}
interface Methods {}
interface Props {
  item: DibsTabCategoryItem
}
