
import Vue from 'vue'

export default Vue.extend({
  name: 'Mall',
  props: {
    /**
     * Mall
     * image : 카드에 사용할 이미지
     * text : 이미지 하단에 들어갈 문구
     * likeCount : 문구 하단에 들어갈 구독 수
     **/
    image: {
      type: String as () => string,
      default: () => '',
    },
    text: {
      type: String as () => string,
      default: () => '',
    },
    likeCount: {
      type: Number,
      default: 0,
    },
    /**
     * Badge
     * badge : 사용 여부
     * badgeText : 배지 안에 들어갈 텍스트
     * badgeX : 배지 가로 컨트롤
     * badgeY : 배지 세로 컨트롤
     *
     * 참고 사항
     * custom img 생성 방법 -> plugins > vuetify 참조
     * custom img 사용 시, badgeColor를 해당 이미지와 같은 색상으로 넣어줘야함...ㅠ
     */
    badge: {
      type: Boolean as () => boolean,
      default: () => false,
    },

    badgeText: {
      type: String as () => string,
      default: () => '',
    },
    badgeIcon: {
      type: String as () => string,
      default: () => '$vuetify.icons.newIcon',
    },
    badgeX: {
      type: String as () => string,
      default: () => '2',
    },
    badgeY: {
      type: String as () => string,
      default: () => '22',
    },
    /**
     * Blind
     * blind : 사용 여부
     */
    blind: {
      type: Boolean as () => boolean,
      default: () => false,
    },
    /**
     * Style
     * textSize : 이미지 하단 문구 사이즈
     * textColor : 이미지 하단 문구 컬러
     * radius : 이미지 곡선 사이즈
     * aspectRatio : 이미지 비율
     * active : 이미지 선택 시, 효과
     */
    textSize: {
      type: String as () => string,
      default: () => '13px',
    },
    textColor: {
      type: String as () => string,
      default: () => '#222222',
    },
    radius: {
      type: String as () => string,
      default: () => '35%',
    },
    aspectRatio: {
      type: Number as () => number,
      default: () => 1 / 1,
    },
    active: {
      type: Boolean as () => boolean,
      default: () => false,
    },
    mallSize: {
      type: String,
      default: '60',
    },
    imageContent: {
      type: String as () => string,
      default: () => '',
    },
    // Mall Border Active Effect 사용할 시에
    activeEffect: {
      type: Boolean,
      default: false,
    },
    borderStyleText: {
      type: String,
      default: '',
    },
    defaultBorderColor: {
      type: String,
      default: 'white',
    },
  },
  filters: {
    oneLine(text: string) {
      if (text.length > 5) {
        return text.slice(0, 5) + '...'
      } else {
        return text
      }
    },
  },
  computed: {
    borderStyle() {
      const { radius } = this

      if (this.borderStyleText) {
        return this.borderStyleText
      }

      return `border-radius: ${radius}; ${
        this.activeEffect ? 'border: 1px solid white' : ''
      }`
    },
    textStyle() {
      return `width:64px`
    },
  },
})
