var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_vm._f("sliderContainer")(_vm.$isMobile()),{directives:[{name:"touch",rawName:"v-touch",value:({
    start: () => _vm.swipeHandler('start'),
    move: () => _vm.swipeHandler('move'),
    end: () => _vm.swipeHandler('off'),
  }),expression:"{\n    start: () => swipeHandler('start'),\n    move: () => swipeHandler('move'),\n    end: () => swipeHandler('off'),\n  }"}],ref:"sliderContainer",tag:"component",class:`relative max-width ${_vm.swipeClass} ${
    _vm.$isMobile() && 'd-flex flex-nowrap overflow-x-auto scroll-none'
  } ${_vm.differentItemClass[0]}`,attrs:{"options":_vm.swiperOption,"center-active":""}},[(_vm.firstSlot)?_c(_vm._f("sliderItem")(_vm.$isMobile()),{tag:"component",class:`${_vm.swipeSliderClass} ${_vm.$isMobile() && 'flex-shrink-0'}`,staticStyle:{"scroll-snap-type":"x mandatory"},on:{"click":function($event){return _vm.handleActive({ id: 0 }, 0)}},nativeOn:{"click":function($event){return _vm.handleActive({ id: 0 }, 0)}}},[_vm._t("first")],2):_vm._e(),_vm._l((_vm.list2),function(item,index){return _c(_vm._f("sliderItem")(_vm.$isMobile()),{key:_vm.indexKey !== '' ? item[_vm.indexKey] : index,tag:"component",class:`d-flex ${_vm.swipeSliderClass} ${_vm.$isMobile() && 'flex-shrink-0 '}
    ${
      index === 0 && !_vm.firstSlot
        ? _vm.differentItemClass[0]
        : _vm.differentItemClass[1]
    } 
    ${_vm.list.length - 1 === index && !_vm.lastSlot && _vm.differentItemClass[2]}
    `},[_c(_vm._f("sliderItemComponent")(_vm.sliderInName),_vm._b({tag:"component",class:`${_vm.itemClass} ${
        _vm.active.yn &&
        _vm.activeItem[_vm.activeKey] &&
        _vm.activeItem[_vm.activeKey] === item[_vm.activeKey]
          ? _vm.itemActiveClass[0]
          : _vm.itemActiveClass[1]
      }`,style:(`color: black; ${
        _vm.sliderInName === 'mall' && item.title === '전체' && 'display : none;'
      } ${_vm.differentItemClass[2]}`),attrs:{"itemKey":_vm.itemKey,"image":item[_vm.imageKey],"text":item.shopName,"radius":"24px","active":item.id && item.id === _vm.activeItem.id,"product":item,"aspectRatio":_vm.aspectRatio,"oneLine":true,"onDiscount":false,"fonts":['rixgo-bold', 'rixgo-regular', 'campton-medium'],"fontSizes":['font-g12-13', 'font-g12-14', 'font-g12-13'],"item":item,"gtagName":_vm.gtagName},on:{"isLikeChange":(yn, id) => _vm.$emit('isLikeChange', yn, id, index),"load":function($event){return _vm.$emit('load')}},nativeOn:{"click":function($event){return _vm.handleActive(item, index)}}},'component',{ ..._vm.newMallProps },false))],1)}),_vm._l((_vm.list),function(item,index){return _c(_vm._f("sliderItem")(_vm.$isMobile()),{key:_vm.indexKey !== '' ? item[_vm.indexKey] + _vm.count : index + _vm.count,tag:"component",class:`d-flex ${_vm.swipeSliderClass} ${_vm.$isMobile() && 'flex-shrink-0 '}
    ${
      index === 0 && !_vm.firstSlot
        ? _vm.list2.length > 0
          ? ''
          : _vm.differentItemClass[0]
        : _vm.differentItemClass[1]
    } 
    ${_vm.list.length - 1 === index && !_vm.lastSlot && _vm.differentItemClass[2]}
    `},[_c(_vm._f("sliderItemComponent")(_vm.sliderInName),_vm._b({tag:"component",class:`${_vm.itemClass} ${
        _vm.active.yn &&
        _vm.activeItem[_vm.activeKey] &&
        _vm.activeItem[_vm.activeKey] === item[_vm.activeKey]
          ? _vm.itemActiveClass[0]
          : _vm.itemActiveClass[1]
      }`,style:(`color: black; ${
        _vm.sliderInName === 'mall' && item.title === '전체' && 'display : none;'
      } ${_vm.differentItemClass[2]} `),attrs:{"itemKey":_vm.itemKey,"image":item[_vm.imageKey],"text":item.shopName,"radius":"24px","active":item.id && item.id === _vm.activeItem.id ? true : false,"product":item,"aspectRatio":_vm.aspectRatio,"oneLine":true,"onDiscount":false,"fonts":['rixgo-bold', 'rixgo-regular', 'campton-medium'],"fontSizes":['font-g12-18', 'font-g12-14', 'font-g12-13'],"item":item,"firstItem":index === 0,"lastItem":_vm.list.length - 1 === index,"gtagName":_vm.gtagName},on:{"click":function($event){return _vm.handleActive(item, index)},"handleActive":function($event){return _vm.handleActive(item, index)},"badgeClick":function($event){return _vm.$emit('badgeClick', item, index)},"isLikeChange":(yn, id) => _vm.$emit('isLikeChange', yn, id, index),"load":function($event){return _vm.$emit('load')}}},'component',
        _vm.badgeToggleKey === ''
          ? { ..._vm.mallProps }
          : { ..._vm.mallProps, badgeIcon: _vm.badgeIcons[item.isSubs ? 0 : 1] }
      ,false))],1)}),(_vm.lastSlot)?_c(_vm._f("sliderItem")(_vm.$isMobile()),{tag:"component",class:`${_vm.swipeSliderClass} ${_vm.$isMobile() && 'flex-shrink-0'}`},[_vm._t("last")],2):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }