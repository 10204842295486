var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"fill-height relative"},[(_vm.badge)?_c('v-icon',{staticClass:"absolute top-0 left-p-40 z-index-1",on:{"click":function($event){return _vm.$emit('badgeClick')}}},[_vm._v(" "+_vm._s(_vm.badgeIcon)+" ")]):_vm._e(),_c('div',{class:`${
      _vm.activeEffect
        ? _vm.active
          ? 'pa-1 mall-border-active'
          : 'pa-1 mall-border'
        : ''
    }`,style:(_vm.activeEffect ? `background-color : ${_vm.defaultBorderColor}` : ''),on:{"click":function($event){return _vm.$emit('handleActive')}}},[_c('v-img',{staticStyle:{"border":"1px solid white"},style:(_vm.borderStyle),attrs:{"aspect-ratio":_vm.aspectRatio,"src":_vm._f("toSrcPostfix")(_vm._f("toSrcPrefix")(_vm.image)),"height":_vm.mallSize,"width":_vm.mallSize,"eager":""}},[(_vm.imageContent === 'Recommend')?_c('span',[_c('v-img',{staticStyle:{"width":"60px","height":"20px","margin-top":"39px"},attrs:{"src":require("../../../assets/images/Recommend.svg")}})],1):_vm._e()])],1),(_vm.text)?_c('div',{class:`font-g12-18 text-center mt-3 ${
      _vm.active ? 'rixgo-bold black--text' : 'rixgo-regular gray500--text'
    }`,style:(_vm.textStyle)},[_vm._v(" "+_vm._s(_vm._f("oneLine")(_vm.text))+" ")]):_vm._e(),(_vm.likeCount)?_c('div',{staticClass:"text-center font-g12-13 gray500--text oneLine",style:(_vm.textStyle)},[_vm._v(" 구독 "+_vm._s(_vm._f("handleTransform")(_vm.likeCount))+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }